<template>
    <div class="field">
        <label>Dirección <span v-if="required" class="required-item">*</span></label>
        <textarea :value="value" @input="$emit('input', $event.target.value)" class="form-control"></textarea>
    </div>
</template>

<script>
import {ref} from "vue";
import InputBase from "@/components/fields/InputBase.vue";

export default {
    name: "InputAddress",
    extends: InputBase,
    setup() {
        const input = ref("");
        return {input};
    }
}
</script>

<style scoped>

</style>
<template>
  <div class="form-container">
    <PreLoader :is-loading="isLoading"></PreLoader>
    <div v-if="fields">
      <LiveStudyWork ref="formComponent" v-if="sueno_c === '1' || sueno_c === '2' || sueno_c === '3'" type="live"
        :fields="fields">
      </LiveStudyWork>
      <InvestmentForm ref="formComponent" v-if="sueno_c === '4'" type="investment" :fields="fields"></InvestmentForm>
      <SponsorForm ref="formComponent" v-if="sueno_c === '5'" type="investment" :fields="fields"></SponsorForm>
      <ProtectionForm ref="formComponent" v-if="sueno_c === '7'" type="protection" :fields="fields"></ProtectionForm>
    </div>
    <div class="container" v-if="fields">
      <vue-recaptcha v-show="showRecaptcha" sitekey="6LfeCwQpAAAAACg1D3IMSzNFhEwSGW1Je8Ls9D4I" size="normal" theme="light"
        hl="tr" :loading-timeout="loadingTimeout" @verify="recaptchaVerified" @expire="recaptchaExpired"
        @fail="recaptchaFailed" @error="recaptchaError" ref="vueRecaptcha">
      </vue-recaptcha>
    </div>
    <div class="container mt-5">
      <ButtonSave @click="save" :preloader="isLoading"></ButtonSave>
    </div>
  </div>
</template>
<script>

import PreLoader from "@/components/helpers/PreLoader.vue";
import LiveStudyWork from "@/components/LiveStudyWork.vue";
import ButtonSave from "@/components/helpers/ButtonSave.vue";
import axios from "axios";
import InvestmentForm from "@/components/InvestmentForm.vue";
import SponsorForm from "@/components/SponsorForm.vue";
import ProtectionForm from "@/components/ProtectionForm.vue";
import vueRecaptcha from 'vue3-recaptcha2';

export default {
  name: 'InitForm',
  props: {},
  data() {
    return {
      fields: null,
      isLoading: false,
      url: 'http://127.0.0.1:8000',
      sueno_c: '1',
      urlRedirect: 'https://cicanada.com',
      campaign_id: '',
      path: 'apps/Leads',
      showRecaptcha: true,
      loadingTimeout: 30000, // 30 seconds
      validCaptcha: false
    }
  },
  methods: {
    save: function () {
      if (!this.validCaptcha) {
        this.$swal({
          title: 'Debe completar el captcha',
          type: 'error'
        });
        return;
      }
      let validation = this.$refs.formComponent.validate();
      if (validation) {
        this.isLoading = !this.isLoading;
        let principal = this.$refs.formComponent.principal;
        principal.sueno_c = String(this.sueno_c);
        principal = this.prepareData(principal);
        let data = {
          principal: principal
        };
        if (this.$refs.formComponent.hasPartner) {
          data.partner = this.prepareData(this.$refs.formComponent.partner);
        }
        data.captcha = this.validCaptcha;
        console.log(data);
        axios.post(`${this.url}/api/${this.path}`, data)
          .then(() => {
            this.isLoading = false;
            this.$swal({
              title: 'Información guardada correctamente',
              type: 'success'
            }).then(() => {
              window.location.href = this.urlRedirect;
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status === 422) {
              this.$swal({
                title: 'Debe completar el captcha',
                type: 'error'
              });
            } else {
              this.$swal({
                title: 'Ha ocurrido un error, por favor vuelva a intentarlo',
                type: 'error'
              });
            }
          });
      } else {
        this.$swal({
          title: 'Debe completar todos los campos',
          type: 'warning'
        });
      }
    },
    load: function () {
      if (window.custom) {
        let custom = window.custom;
        if (custom.url) {
          this.url = custom.url;
        }
        if (custom.sueno_c) {
          this.sueno_c = custom.sueno_c;
        }
        if (custom.urlRedirect) {
          this.urlRedirect = custom.urlRedirect;
        }
        if (custom.campaign) {
          this.campaign_id = custom.campaign;
        }
        if (custom.path) {
          this.path = custom.path;
        }
      }
    },
    prepareData: function (object) {
      if (this.sueno_c) {
        object.sueno_c = this.sueno_c;
      }
      if (this.campaign_id) {
        object.campaign_id = this.campaign_id;
      }
      return object;
    },
    recaptchaVerified(value) {
      this.validCaptcha = value;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.validCaptcha = null;
    },
    recaptchaError() {
      this.validCaptcha = null;
    }
  },
  components: {
    ProtectionForm,
    SponsorForm,
    InvestmentForm,
    ButtonSave,
    LiveStudyWork,
    PreLoader,
    vueRecaptcha
  },
  created() {
    this.load();
    this.isLoading = true;
    axios.get(`${this.url}/api/apps/Leads/fields`)
      .then(response => {
        this.fields = response.data.module_fields;
        this.isLoading = false;

      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  mounted() {

  }
}
</script>
<style scoped>
.mt-5 {
  margin-top: 1.5rem;
}
</style>
<template>
  <div class="field">
    <label>{{ label }}<span v-if="required" class="required-item"></span></label>
    <div class="column multienum-field">
      <div v-for="(option, key) in fieldOptions" :key="key">
        <label v-if="option.value&&option.name">
          <input type="checkbox" :value="option.name">{{ option.value }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import InputBase from "@/components/fields/InputBase.vue";
// import CustomCheckbox from "@/components/fields/CustomCheckbox.vue";

export default {
  name: "InputMultiEnum",
  // components: {CustomCheckbox},
  extends: InputBase,
  emits: ["update:value"],
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fieldOptions: [],
      selectedOptions: []
    }
  },
  mounted() {
    let keys = Object.keys(this.options);
    for (let key of keys) {
      if (key) {
        let value = this.options[key];
        if (typeof value === 'object' && value !== null) {
          value.selected = false;
          this.fieldOptions.push(value);
        } else {
          let result = String(value);
          if (result.length > 0) {
            this.fieldOptions.push({name: key, value: String(value)});
          }
        }
      }
    }
  },
  setup(props, context) {
    const check = (optionId, checked) => {
      let updatedValue = [...props.value];
      if (checked) {
        updatedValue.push(optionId);
      } else {
        updatedValue.splice(updatedValue.indexOf(optionId), 1);
      }
      context.emit("update:value", updatedValue);
    };

    return {
      check,
    };
  },
  methods: {
    updateSelected: function () {
    }
  }
}
</script>

<style scoped>
.multienum-field {
  width: 100%;
}
</style>
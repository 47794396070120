<template>
    <div class="form-container">
        <InitForm></InitForm>
    </div>
</template>

<script>
import InitForm from "@/InitForm.vue";

export default {
    name: 'App',
    components: { InitForm },
}
</script>

<style>
.field label {
    display: block;
    margin-bottom: 0.5rem;
}

.field .form-control {
    display: block;
    width: 100%;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #495057;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field .form-control:active {
    border: 1px solid #e10707;
}

.container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 960px;
}

.column {
    box-sizing: border-box;
    padding: 1rem;
    width: 50%;
    float: left;
}

@media (max-width: 767px) {
    .column {
        width: 100%;
        float: none;
    }
}

.w-full {
    width: 100%;
}

.column-w-full {
    padding: 1rem;
    width: 100%;
    overflow: hidden;
}

.form-container {
    margin-bottom: 1rem;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.required-item:after {
    margin-left: .5rem;
    content: '*';
    color: red;
}
</style>
<template>
    <div class="spinner-container" v-if="isLoading">
        <div class="spinner"></div>
    </div>
</template>

<script>
export default {
    name: "PreLoader",
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style scoped>

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.51);
    z-index: 2000;
}

.spinner {
    width: 56px;
    height: 56px;
    border: 11.2px #e4202d double;
    border-left-style: solid;
    border-radius: 50%;
    animation: spinner-aib1d7 0.75s infinite linear;
    position: fixed;
    top: 46%;
    left: 48%;
}

@keyframes spinner-aib1d7 {
    to {
        transform: rotate(360deg);
    }
}
</style>
<template>
    <div class="field">
        <label>{{ label }}<span v-if="required" class="required-item"></span></label>
        <input type="text" v-model="content" >
    </div>
</template>

<script>
import InputBase from "@/components/fields/InputBase.vue";

export default {
    name: "InputRadioEnum",
    extends: InputBase,
    props: {
        options: {
            type: Object,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="field">
        <label>{{ label }}<span v-if="required" class="required-item"></span></label>
        <VueDatePicker v-model="content" locale="es" cancelText="Cancelar" selectText="Seleccionar"
                       model-type="yyyy-MM-dd"
                       @update:model-value="handleInput"></VueDatePicker>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import InputBase from "@/components/fields/InputBase.vue";

export default {
    name: "InputDate",
    extends: InputBase,
    components: {
        VueDatePicker
    },
    methods: {
        handleInput() {
            this.$emit('input', this.content);
            this.$emit('update:value', this.content);
        }
    }
}
</script>

<style scoped>

</style>
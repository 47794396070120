<template>
  <div class="field">
    <label>{{ label }}<span v-if="required" class="required-item"></span></label>
    <select :value="content" class="form-control">
      <option value="">Seleccione</option>
      <option v-for="(option, key) in fieldOptions" :key="key" :value="option.name">
        {{ option.value }}
      </option>
    </select>
  </div>
</template>

<script>
import InputBase from "@/components/fields/InputBase.vue";

export default {
  name: "InputEnum",
  extends: InputBase,
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fieldOptions: []
    }
  },
  mounted() {
    if (!this.options) {
      return;
    }
    let keys = Object.keys(this.options);
    for (let key of keys) {
      if (key) {
        let value = this.options[key];
        if (typeof value === 'object' && value !== null) {
          this.fieldOptions.push(value);
        } else {
          let result = String(value);
          if (result.length > 0) {
            this.fieldOptions.push({name: key, value: String(value)});
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="field">
    <label>{{ label }}<span v-if="required" class="required-item"></span></label>
    <div class="input-group">
      <div class="select" @click="toggleList" tabindex="0">
        <div class="dropdown-label">
                    <span class="icon-container">
                        <img v-if="selectedCountry" :src="selectedCountry.flag" alt="Icono de la opción" class="flag"/>
                    </span>
          <span v-if="selectedCountry" class="selected">{{ `+${selectedCountry.callingCodes}` }}</span>
          <span v-else class="unselect">Seleccionar</span>
        </div>
        <ul :class="{ 'hidden': !showList }" @click.stop>
          <li>
            <input type="text" v-model="searchTerm" @click.stop placeholder="Buscar...">
          </li>
          <li v-for="(country,key) in filteredOptions" :key="key" @click="selectOption(country)">
            <img class="flag" :src="country.flag" alt="Icono de la opción"/>
            <span>+{{ country.callingCodes[0] }} {{ country.name }}</span>
          </li>
        </ul>
      </div>
      <input type="tel" v-model="content" @input="handleInput" class="phone-number">
    </div>
  </div>
</template>

<script>

import axios from "axios";
import InputBase from "@/components/fields/InputBase.vue";

export default {
  name: "InputPhone",
  extends: InputBase,
  data() {
    return {
      showList: false,
      phoneNumber: '',
      countryCode: '+1',
      countries: [],
      selectedCountry: null,
      searchTerm: '',
      url: 'http://127.0.0.1:8000',
    }
  },
  mounted() {
    let custom = window.custom;
    if (custom.url) {
      this.url = custom.url;
    }
    let urlPhones = `${this.url}/api/helpers/phones`;
    axios.get(urlPhones)
        .then(response => {
          this.countries = response.data;
        })
        .catch(error => {
          console.log(error);
        });
  },
  computed: {
    filteredOptions() {
      if (!this.searchTerm) {
        return this.countries
      }
      return this.countries.filter(option =>
          option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    }
  },
  methods: {
    toggleList() {
      this.showList = !this.showList;
    },
    selectOption(value) {
      this.selectedCountry = value
      this.toggleList();
      this.handleInput()
    },
    handleInput() {
      let countryCode = '';
      if (this.selectedCountry && this.selectedCountry.callingCodes.length > 0) {
        countryCode = this.selectedCountry.callingCodes[0];
      }
      let value = countryCode + '' + this.content;
      this.$emit('input', value);
      this.$emit('update:value', value);
    }
  }
}
</script>

<style scoped>
.input-group {
  display: flex;
  align-items: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hidden {
  display: none;
}

.phone-number {
  height: 2.9rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid;
  width: 80%;
}

.select {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border: 1px solid;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 20%;
  height: 3rem;
}

.select ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  overflow-y: scroll;
  max-height: 25rem;
  width: 17rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.select li {
  display: flex;
  align-items: center;
  padding: 5px;
}

.select img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.dropdown-label .icon-container {
  width: 50%;
}

.dropdown-label .selected {
  width: 50%;
}

.dropdown-label .unselect {
  width: 100%;
  margin-top: 1rem;
}
</style>
<template>
  <div class="field">
    <label>{{ label }}<span v-if="required" class="required-item"></span></label>
    <select :value="content" class="form-control">
      <option value="">Seleccione</option>
      <option v-for="(option, key) in fieldOptions" :key="key" :value="option.name">
        {{ option.value }}
      </option>
    </select>
  </div>
</template>

<script>
import InputBase from "@/components/fields/InputBase.vue";

export default {
  name: "InputDynamicenum",
  extends: InputBase,
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      baseOptions: {},
      fieldOptions: []
    }
  },
  mounted() {
    this.baseOptions = this.options;
    this.prepareOptions();
  },
  methods: {
    prepareOptions() {
      this.fieldOptions = [];
      let keys = Object.keys(this.baseOptions);
      for (let key of keys) {
        if (key) {
          let value = this.baseOptions[key];
          if (typeof value === 'object' && value !== null) {
            this.fieldOptions.push(value);
          } else {
            let result = String(value);
            if (result.length > 0) {
              this.fieldOptions.push({name: key, value: String(value)});
            }
          }
        }
      }
    }
  },
  watch: {
    options(newValue) {
      this.baseOptions = newValue;
      this.prepareOptions();
    }
  }
}
</script>

<style scoped>

</style>
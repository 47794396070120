<template>
    <button class="btn btn-red">
        <span class="text" v-if="!preloader">Enviar</span>
        <span class="loader" v-if="preloader"></span>
    </button>
</template>

<script>
export default {
    name: "ButtonSave",
    props: {
        preloader: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.btn {
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
}

.btn-red {
    background-color: #d32f2f;
}

.btn-red:hover {
    background-color: #b71c1c;
}

.text {
    z-index: 1;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg); /* Agregar rotate(0deg) aquí */
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
    z-index: 0;
    animation-timing-function: linear; /* Agregar esta propiedad */
}

@keyframes spin {
    to {
        transform: translate(-50%, -50%) rotate(360deg); /* Cambiar la propiedad left a transform y agregar rotate(360deg) aquí */
    }
}
</style>
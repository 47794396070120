<template>
  <div v-if="type && sueno">
    <div class="container">
      <div class="principal">
        <FieldItem v-model="principal.email1" :required="true" :field="fields['email1']"></FieldItem>
        <FieldItem v-model="principal.phone_home" :required="true" :field="fields['phone_home']" :label="'Teléfono'">
        </FieldItem>
        <FieldItem v-model="principal.first_name" :required="true" :field="fields['first_name']"></FieldItem>
        <FieldItem v-model="principal.last_name" :required="true" :field="fields['last_name']"></FieldItem>
        <FieldItem v-model="principal.birthdate" :required="true" :field="fields['birthdate']"></FieldItem>
        <FieldItem v-model="principal.pais_c" :required="true" :field="fields['pais_c']"></FieldItem>
        <FieldItem v-model="principal.estado_civiil_c" :required="true" :field="fields['estado_civiil_c']"></FieldItem>
        <FieldItem v-model="principal.nivel_educativo_c" :required="true" :field="fields['nivel_educativo_c']">
        </FieldItem>
        <FieldItem v-model="principal.area_conocimiento_c" :required="true" @change="areaConocimientoChange"
          :field="fields['area_conocimiento_c']"></FieldItem>
        <FieldItem v-model="principal.profesion_c" :required="true" :field="fieldItemData['profesion_c']"
          :options="areaConocimientoOptions"></FieldItem>
        <FieldItem v-model="principal.nivel_ingles_c" :required="true" :field="fields['nivel_ingles_c']"></FieldItem>
        <FieldItem v-model="principal.nivel_frances_c" :required="true" :field="fields['nivel_frances_c']"></FieldItem>
        <FieldItem v-model="principal.tiene_visa_americana_c" :required="true" :field="fields['tiene_visa_americana_c']">
        </FieldItem>
        <FieldItem v-model="principal.tiene_visa_canadiense_c" :required="true"
          :field="fields['tiene_visa_canadiense_c']"></FieldItem>
        <FieldItem v-model="principal.situacion_c" :required="true" :field="fields['situacion_c']"></FieldItem>
        <FieldItem v-model="principal.viajado_destinos_c" :required="true" :field="fields['viajado_destinos_c']">
        </FieldItem>
        <FieldItem v-model="principal.experiencia_laboral_c" :required="true" :field="fields['experiencia_laboral_c']">
        </FieldItem>
        <FieldItem v-model="principal.rango_dinero_c" :required="true" :field="fields['rango_dinero_c']"></FieldItem>
        <FieldItem v-model="principal.delito_su_pais_c" :required="true" :field="fields['delito_su_pais_c']"></FieldItem>
        <FieldItem v-model="principal.padres_hermanos_refugio_c" :required="true"
          :field="fields['padres_hermanos_refugio_c']"></FieldItem>
        <FieldItem v-model="principal.familia_canada_c" :required="true" :field="fields['familia_canada_c']"></FieldItem>
        <FieldItem v-model="principal.oferta_empleo_valida_c" :required="true" v-if="sueno === '3'"
          :field="fields['oferta_empleo_valida_c']"></FieldItem>
        <FieldItem v-model="principal.observaciones_c" :required="true" :field="fields['observaciones_c']" :w-full="true">
        </FieldItem>
      </div>
      <div class="partner" v-if="principal.estado_civiil_c === 'casado' || principal.estado_civiil_c === 'union_libre'">
        <h3>Información del conyuge</h3>
        <FieldItem v-model="partner.email1" :required="true" :field="fields['email1']"></FieldItem>
        <FieldItem v-model="partner.phone_home" :required="true" :field="fields['phone_home']" :label="'Teléfono'">
        </FieldItem>
        <FieldItem v-model="partner.first_name" :required="true" :field="fields['first_name']"></FieldItem>
        <FieldItem v-model="partner.last_name" :required="true" :field="fields['last_name']"></FieldItem>
        <FieldItem v-model="partner.birthdate" :required="true" :field="fields['birthdate']"></FieldItem>
        <FieldItem v-model="partner.pais_c" :required="true" :field="fields['pais_c']"></FieldItem>
        <FieldItem v-model="partner.nivel_educativo_c" :required="true" :field="fields['nivel_educativo_c']"></FieldItem>
        <FieldItem v-model="partner.area_conocimiento_c" :required="true" :field="fields['area_conocimiento_c']">
        </FieldItem>
        <FieldItem v-model="partner.profesion_c" :required="true" :field="fieldItemData['profesion_c']"
          :options="areaConocimientoOptions"></FieldItem>
        <FieldItem v-model="partner.nivel_ingles_c" :required="true" :field="fields['nivel_ingles_c']"></FieldItem>
        <FieldItem v-model="partner.nivel_frances_c" :required="true" :field="fields['nivel_frances_c']"></FieldItem>
        <FieldItem v-model="partner.tiene_visa_americana_c" :required="true" :field="fields['tiene_visa_americana_c']">
        </FieldItem>
        <FieldItem v-model="partner.tiene_visa_canadiense_c" :required="true" :field="fields['tiene_visa_canadiense_c']">
        </FieldItem>
        <FieldItem v-model="partner.situacion_c" :required="true" :field="fields['situacion_c']"></FieldItem>
        <FieldItem v-model="partner.viajado_destinos_c" :required="true" :field="fields['viajado_destinos_c']">
        </FieldItem>
        <FieldItem v-model="partner.experiencia_laboral_c" :required="true" :field="fields['experiencia_laboral_c']">
        </FieldItem>
        <FieldItem v-model="partner.rango_dinero_c" :required="true" :field="fields['rango_dinero_c']"></FieldItem>
        <FieldItem v-model="partner.delito_su_pais_c" :required="true" :field="fields['delito_su_pais_c']"></FieldItem>
        <FieldItem v-model="partner.padres_hermanos_refugio_c" :required="true"
          :field="fields['padres_hermanos_refugio_c']"></FieldItem>
        <FieldItem v-model="partner.familia_canada_c" :required="true" :field="fields['familia_canada_c']"></FieldItem>
        <FieldItem v-model="partner.oferta_empleo_valida_c" v-if="sueno === '3'" :required="true"
          :field="fields['oferta_empleo_valida_c']"></FieldItem>
        <FieldItem v-model="partner.observaciones_c" :required="true" :field="fields['observaciones_c']" :w-full="true">
        </FieldItem>
      </div>
    </div>
  </div>
</template>

<script>

import FieldItem from "@/components/FieldItem.vue";
import BaseForm from "@/components/BaseForm.vue";

export default {
  name: "LiveStudyWork",
  extends: BaseForm,
  components: {
    FieldItem,
  },
  props: {
    fields: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDataValid: true,
      hasPartner: false,
      sueno: '',
      principal: {
        email1: '',
        phone_home: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        pais_c: '',
        estado_civiil_c: '',
        nivel_educativo_c: '',
        area_conocimiento_c: '',
        profesion_c: '',
        nivel_ingles_c: '',
        nivel_frances_c: '',
        tiene_visa_americana_c: '',
        tiene_visa_canadiense_c: '',
        situacion_c: [],
        viajado_destinos_c: '',
        experiencia_laboral_c: '',
        rango_dinero_c: '',
        delito_su_pais_c: '',
        padres_hermanos_refugio_c: '',
        familia_canada_c: '',
        oferta_empleo_valida_c: '',
        observaciones_c: '',
      },
      partner: {
        email1: '',
        phone_home: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        pais_c: '',
        nivel_educativo_c: '',
        area_conocimiento_c: '',
        profesion_c: '',
        nivel_ingles_c: '',
        nivel_frances_c: '',
        tiene_visa_americana_c: '',
        tiene_visa_canadiense_c: '',
        situacion_c: [],
        viajado_destinos_c: '',
        experiencia_laboral_c: '',
        rango_dinero_c: '',
        delito_su_pais_c: '',
        padres_hermanos_refugio_c: '',
        familia_canada_c: '',
        oferta_empleo_valida_c: '',
        observaciones_c: '',
      },
      areaConocimientoOptions: {},
      areaConocimientoOptionsPartner: {},
      baseOptions: {},
      fieldItemData: null
    }
  },
  mounted() {
    let custom = window.custom;
    if (custom.sueno_c) {
      this.sueno = String(custom.sueno_c);
    }

  },
  created() {
    this.baseOptions = this.fields['profesion_c']['options'];
    this.fieldItemData = this.fields;
  },
  computed() {
  },
  methods: {
    validate: function () {
      let principalIsValid = this.objectValidation(this.principal);
      let partnerIsValid = true;
      if (principalIsValid && this.principal.estado_civiil_c === 'union_libre' || this.principal.estado_civiil_c === 'casado') {
        this.hasPartner = true;
        this.partner.estado_civiil_c = this.principal.estado_civiil_c;
        partnerIsValid = this.objectValidation(this.partner);
      }
      this.isDataValid = principalIsValid && partnerIsValid;
      return this.isDataValid;
    },
    objectValidation: function (object) {
      let passValidation = [];
      passValidation.push(this.validateRequired(object.email1));
      passValidation.push(this.validateRequired(object.phone_home));
      passValidation.push(this.validateRequired(object.first_name));
      passValidation.push(this.validateRequired(object.last_name));
      passValidation.push(this.validateRequired(object.birthdate));
      passValidation.push(this.validateRequired(object.pais_c));
      passValidation.push(this.validateRequired(object.estado_civiil_c));
      passValidation.push(this.validateRequired(object.nivel_educativo_c));
      passValidation.push(this.validateRequired(object.area_conocimiento_c));
      passValidation.push(this.validateRequired(object.profesion_c));
      passValidation.push(this.validateRequired(object.nivel_ingles_c));
      passValidation.push(this.validateRequired(object.nivel_frances_c));
      passValidation.push(this.validateRequired(object.tiene_visa_americana_c));
      passValidation.push(this.validateRequired(object.tiene_visa_canadiense_c));
      passValidation.push(this.validateRequired(object.situacion_c));
      passValidation.push(this.validateRequired(object.viajado_destinos_c));
      passValidation.push(this.validateRequired(object.experiencia_laboral_c));
      passValidation.push(this.validateRequired(object.rango_dinero_c));
      passValidation.push(this.validateRequired(object.delito_su_pais_c));
      passValidation.push(this.validateRequired(object.padres_hermanos_refugio_c));
      passValidation.push(this.validateRequired(object.familia_canada_c));
      if (this.sueno === '3') {
        passValidation.push(this.validateRequired(object.oferta_empleo_valida_c));
      }
      passValidation.push(this.validateRequired(object.observaciones_c));
      return !passValidation.some(item => item === false);
    },
    validateRequired: function (item) {
      return item !== undefined && item !== null && item !== '';
    },
    areaConocimientoChange: function () {
      let value = this.principal.area_conocimiento_c;
      if (value) {
        let keyProfessions = Object.keys(this.baseOptions);
        this.areaConocimientoOptions = {};
        keyProfessions.forEach(value1 => {
          if (value1.startsWith(value)) {
            this.areaConocimientoOptions[value1] = this.baseOptions[value1];
          }
        });
        console.log('change here');
        this.fieldItemData['profesion_c']['options'] = this.areaConocimientoOptions;
      }
    }
  },

}
</script>

<style scoped>
FieldItem {
  overflow: hidden;
}
</style>
<script setup>
</script>
<template>
  <div v-if="field&&fieldItem" :class="cssClass">
    <InputAddress v-model="content" :required="required" @input="handleInput" v-if="field.type==='address'"
                  :label="labelField"></InputAddress>
    <InputBool v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='bool'"
               :label="labelField"></InputBool>
    <InputCurrency v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='currency'"
                   :label="labelField"></InputCurrency>
    <InputDate v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='date'"
               :label="labelField"></InputDate>
    <InputDecimal v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='decimal'"
                  :label="labelField"></InputDecimal>
    <InputEnum v-model="content" :required="required" @input="handleInput" v-else-if="typeField==='enum'"
               :label="labelField"
               :options="optionItems"></InputEnum>
    <InputFloat v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='float'"
                :label="labelField"></InputFloat>
    <InputInt v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='int'"
              :label="labelField"></InputInt>
    <InputName v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='name'"
               :label="labelField"></InputName>
    <InputPhone :required="required" @input="handlePhone" v-else-if="field.type==='phone'"
                :label="labelField"></InputPhone>
    <InputRadioEnum v-model="content" :required="required" @input="handleInput"
                    v-else-if="field.type==='radionenum'"
                    :label="labelField"></InputRadioEnum>
    <InputText v-model="content" :required="required" @input="handleInput" v-else-if="typeField==='text'"
               :label="labelField"></InputText>
    <InputUrl v-model="content" :required="required" @input="handleInput" v-else-if="field.type==='url'"
              :label="labelField"></InputUrl>
    <InputVarchar v-model="content" :required="required" @input="handleInput" v-else-if="typeField==='varchar'"
                  :label="labelField"></InputVarchar>
    <InputMultiEnum :required="required" @input="handleMultiEnum"
                    v-else-if="field.type==='multienum'"
                    :label="labelField"
                    :options="field.options"></InputMultiEnum>
    <InputDynamicenum v-model="content" :required="required" @input="handleInput"
                      v-else-if="field.type==='dynamicenum'"
                      :label="labelField"
                      :options="optionItems"></InputDynamicenum>
    <div v-else>
      The field {{ field.type }} is not supported
    </div>
  </div>
</template>

<script>
// import {ref} from "vue";
import InputAddress from "@/components/fields/InputAddress.vue";
import InputBool from "@/components/fields/InputBool.vue";
import inputCurrency from "@/components/fields/InputCurrency.vue";
import InputDate from "@/components/fields/InputDate.vue";
import InputDecimal from "@/components/fields/InputDecimal.vue";
import InputVarchar from "@/components/fields/InputVarchar.vue";
import InputUrl from "@/components/fields/InputUrl.vue";
import InputText from "@/components/fields/InputText.vue";
import InputRadioEnum from "@/components/fields/InputRadioEnum.vue";
import InputPhone from "@/components/fields/InputPhone.vue";
import InputName from "@/components/fields/InputName.vue";
import InputInt from "@/components/fields/InputInt.vue";
import InputFloat from "@/components/fields/InputFloat.vue";
import InputEnum from "@/components/fields/InputEnum.vue";
import InputMultiEnum from "@/components/fields/InputMultiEnum.vue";
import InputDynamicenum from "@/components/fields/InputDynamicenum.vue";


export default {
  name: "FieldItem",
  props: {
    field: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    wFull: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cssClass: '',
      labelField: '',
      content: this.value,
      selected: [],
      fieldItem: null,
      optionItems: [],
      typeField: ''
    }
  },
  components: {
    InputDynamicenum,
    InputAddress,
    InputBool,
    inputCurrency,
    InputDate,
    InputDecimal,
    InputEnum,
    InputFloat,
    InputInt,
    InputName,
    InputPhone,
    InputRadioEnum,
    InputText,
    InputUrl,
    InputVarchar,
    InputMultiEnum
  },
  mounted() {
    if (this.field) {
      if (this.type) {
        this.typeField = this.type;
      } else {
        this.typeField = this.field.type;
      }
      this.cssClass = `${this.field.type} ${this.field.name}`;
      if (this.wFull) {
        this.cssClass = `${this.cssClass} column-w-full`;
      } else {
        this.cssClass = `${this.cssClass} column`;
      }
      if (!this.label) {
        this.labelField = this.field.label;
      } else {
        this.labelField = this.label;
      }
      this.fieldItem = this.field;
      if (this.options) {
        this.fieldItem['options'] = this.options;
      }
      this.optionItems = this.fieldItem['options'];
    }
  },
  computed() {
  },
  methods: {
    handleInput: function (e) {
      if (typeof e === 'object') {
        let value = null;
        if (e.target._modelValue) {
          value = e.target._modelValue;
          value.push(e.target.value);
        } else {
          value = e.target.value;
        }
        this.$emit('input', value);
        this.$emit('update:value', value);
        this.$emit('update:modelValue', value);
      } else {
        this.$emit('input', e);
        this.$emit('update:value', e);
        this.$emit('update:modelValue', e);
      }

    },
    handlePhone: function (e) {
      if (typeof e === 'object') {
        this.$emit('input', e.target.value);
        this.$emit('update:value', e.target.value);
        this.$emit('update:modelValue', e.target.value);
      } else {
        this.$emit('input', e);
        this.$emit('update:value', e);
        this.$emit('update:modelValue', e);
      }
    },
    handleMultiEnum: function (e) {
      if (typeof e === 'object') {
        let value = null;
        if (e.target._modelValue) {
          value = e.target._modelValue;
          value.push(e.target.value);
        } else {
          value = e.target.value;
        }
        const index = this.selected.indexOf(value);
        if (index > -1) {
          this.selected.splice(index, 1);
        } else {
          this.selected.push(value);
        }
        this.$emit('input', this.selected);
        this.$emit('update:value', this.selected);
        this.$emit('update:modelValue', this.selected);
      }
    }
  },
  setup() {
  },
  watch: {
    options(newValue) {
      this.optionItems = newValue;
    }
  }
}
</script>

<style scoped>

</style>
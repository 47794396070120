<template>
  <div v-if="type">
    <div class="container">
      <div class="principal">
        <FieldItem v-model="principal.email1" :required="true" :field="fields['email1']"></FieldItem>
        <FieldItem v-model="principal.phone_home" :required="true" :field="fields['phone_home']"
                   :label="'Teléfono'"></FieldItem>
        <FieldItem v-model="principal.first_name" :required="true" :field="fields['first_name']"></FieldItem>
        <FieldItem v-model="principal.last_name" :required="true" :field="fields['last_name']"></FieldItem>
        <FieldItem v-model="principal.birthdate" :required="true" :field="fields['birthdate']"></FieldItem>
        <FieldItem v-model="principal.pais_c" :required="true" :field="fields['pais_c']"></FieldItem>
        <FieldItem v-model="principal.estado_civiil_c" :required="true"
                   :field="fields['estado_civiil_c']"></FieldItem>
        <FieldItem v-model="principal.nivel_educativo_c" :required="true"
                   :field="fields['nivel_educativo_c']"></FieldItem>
        <FieldItem v-model="principal.tiene_experiencia_gestion_em_c" :required="true"
                   :field="fields['tiene_experiencia_gestion_em_c']"></FieldItem>
        <FieldItem v-model="principal.cuantos_anos_propietario_ges_c" :required="true"
                   :field="fields['cuantos_anos_propietario_ges_c']"></FieldItem>
        <FieldItem v-model="principal.cunatos_anos_trabajador_c" :required="true"
                   :field="fields['cunatos_anos_trabajador_c']"></FieldItem>
        <FieldItem v-model="principal.fondos_disponibles_invertir_c" :required="true"
                   :field="fields['fondos_disponibles_invertir_c']"></FieldItem>
        <FieldItem v-model="principal.nivel_ingles_c" :required="true"
                   :field="fields['nivel_ingles_c']"></FieldItem>
        <FieldItem v-model="principal.nivel_frances_c" :required="true"
                   :field="fields['nivel_frances_c']"></FieldItem>
        <FieldItem v-model="principal.tiene_visa_americana_c" :required="true"
                   :field="fields['tiene_visa_americana_c']"></FieldItem>
        <FieldItem v-model="principal.tiene_visa_canadiense_c" :required="true"
                   :field="fields['tiene_visa_canadiense_c']"></FieldItem>
        <FieldItem v-model="principal.actividad_inversion_c" :required="true"
                   :field="fields['actividad_inversion_c']"></FieldItem>
        <FieldItem v-model="principal.bienes_posee_c" :required="true"
                   :field="fields['bienes_posee_c']"></FieldItem>
        <FieldItem v-model="principal.numero_empleados_c" :required="true"
                   :field="fields['numero_empleados_c']"></FieldItem>
        <FieldItem v-model="principal.provincia_region_inversion_c" :required="true"
                   :field="fields['provincia_region_inversion_c']"></FieldItem>
        <FieldItem v-model="principal.observaciones_c" :required="true"
                   :w-full="true"
                   :field="fields['observaciones_c']"></FieldItem>
      </div>
      <div class="partner" v-if="principal.estado_civiil_c==='casado'||principal.estado_civiil_c==='union_libre'">
        <h3>Formulario de acompañante</h3>
        <FieldItem v-model="partner.email1" :required="true" :field="fields['email1']"></FieldItem>
        <FieldItem v-model="partner.phone_home" :required="true" :field="fields['phone_home']"
                   :label="'Teléfono'"></FieldItem>
        <FieldItem v-model="partner.first_name" :required="true" :field="fields['first_name']"></FieldItem>
        <FieldItem v-model="partner.last_name" :required="true" :field="fields['last_name']"></FieldItem>
        <FieldItem v-model="partner.birthdate" :required="true" :field="fields['birthdate']"></FieldItem>
        <FieldItem v-model="partner.pais_c" :required="true" :field="fields['pais_c']"></FieldItem>
      </div>
    </div>
  </div>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import FieldItem from "@/components/FieldItem.vue";

export default {
  name: "InvestmentForm",
  extends: BaseForm,
  components: {
    FieldItem,
  },
  props: {
    fields: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDataValid: true,
      hasPartner: false,
      principal: {
        email1: '',
        phone_home: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        pais_c: '',
        estado_civiil_c: '',
        nivel_educativo_c: '',
        tiene_experiencia_gestion_em_c: '',
        cuantos_anos_propietario_ges_c: '',
        cunatos_anos_trabajador_c: '',
        fondos_disponibles_invertir_c: '',
        nivel_ingles_c: '',
        nivel_frances_c: '',
        tiene_visa_americana_c: '',
        tiene_visa_canadiense_c: '',
        actividad_inversion_c: '',
        bienes_posee_c: '',
        numero_empleados_c: '',
        provincia_region_inversion_c: '',
        observaciones_c: '',
      },
      partner: {
        email1: '',
        phone_home: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        pais_c: '',
      }
    }
  },
  methods: {
    validate: function () {
      let principalIsValid = this.objectValidation(this.principal);
      let partnerIsValid = true;
      if (principalIsValid && this.principal.estado_civiil_c === 'union_libre' || this.principal.estado_civiil_c === 'casado') {
        this.hasPartner = true;
        partnerIsValid = this.partnerValidation(this.partner);
      }
      this.isDataValid = principalIsValid && partnerIsValid;
      return this.isDataValid;
    },
    partnerValidation: function (object) {
      let passValidation = [];
      passValidation.push(this.validateRequired(object.email1));
      passValidation.push(this.validateRequired(object.phone_home));
      passValidation.push(this.validateRequired(object.first_name));
      passValidation.push(this.validateRequired(object.last_name));
      passValidation.push(this.validateRequired(object.birthdate));
      passValidation.push(this.validateRequired(object.pais_c));
      return !passValidation.some(item => item === false);
    },
    objectValidation: function (object) {
      let passValidation = [];
      passValidation.push(this.validateRequired(object.email1));
      passValidation.push(this.validateRequired(object.phone_home));
      passValidation.push(this.validateRequired(object.first_name));
      passValidation.push(this.validateRequired(object.last_name));
      passValidation.push(this.validateRequired(object.birthdate));
      passValidation.push(this.validateRequired(object.pais_c));
      passValidation.push(this.validateRequired(object.estado_civiil_c));
      passValidation.push(this.validateRequired(object.nivel_educativo_c));
      passValidation.push(this.validateRequired(object.tiene_experiencia_gestion_em_c));
      passValidation.push(this.validateRequired(object.cuantos_anos_propietario_ges_c));
      passValidation.push(this.validateRequired(object.cunatos_anos_trabajador_c));
      passValidation.push(this.validateRequired(object.fondos_disponibles_invertir_c));
      passValidation.push(this.validateRequired(object.nivel_ingles_c));
      passValidation.push(this.validateRequired(object.nivel_frances_c));
      passValidation.push(this.validateRequired(object.tiene_visa_americana_c));
      passValidation.push(this.validateRequired(object.tiene_visa_canadiense_c));
      passValidation.push(this.validateRequired(object.actividad_inversion_c));
      passValidation.push(this.validateRequired(object.bienes_posee_c));
      passValidation.push(this.validateRequired(object.numero_empleados_c));
      passValidation.push(this.validateRequired(object.provincia_region_inversion_c));
      passValidation.push(this.validateRequired(object.observaciones_c));
      return !passValidation.some(item => item === false);
    },
    validateRequired: function (item) {
      return item !== undefined && item !== null && item !== '';
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="field">
    <label>{{ label }}<span v-if="required" class="required-item"></span></label>
    <input v-model="content" class="form-control">
  </div>
</template>

<script>
import InputBase from "@/components/fields/InputBase.vue";

export default {
  name: "InputVarchar",
  extends: InputBase,
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>
import { createApp } from 'vue'
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);
app.use(VueSweetalert2);
app.component('init-form', require('./InitForm.vue').default);
window.Swal = app.config.globalProperties.$swal;
app.mount('#app');

<template>
  <div v-if="type">
    <div class="container">
      <div class="principal">
        <FieldItem v-model="principal.email1" :required="true" :field="fields['email1']"></FieldItem>
        <FieldItem v-model="principal.phone_home" :required="true" :field="fields['phone_home']"
                   :label="'Teléfono'"></FieldItem>
        <FieldItem v-model="principal.first_name" :required="true" :field="fields['first_name']"></FieldItem>
        <FieldItem v-model="principal.last_name" :required="true" :field="fields['last_name']"></FieldItem>
        <FieldItem v-model="principal.birthdate" :required="true" :field="fields['birthdate']"></FieldItem>
        <FieldItem v-model="principal.pais_c" :required="true" :field="fields['pais_c']"></FieldItem>
        <FieldItem v-model="principal.estado_civiil_c" :required="true"
                   :field="fields['estado_civiil_c']"></FieldItem>
        <FieldItem v-model="principal.refugio_01_c" :required="true"
                   :field="fields['refugio_01_c']"></FieldItem>
        <FieldItem v-model="principal.refugio_02_c" :required="true"
                   :field="fields['refugio_02_c']"></FieldItem>
        <FieldItem v-model="principal.refugio_03_c" :required="true"
                   :field="fields['refugio_03_c']"></FieldItem>
        <FieldItem v-model="principal.refugio_04_c" :required="true"
                   :field="fields['refugio_04_c']"></FieldItem>
        <FieldItem v-model="principal.refugio_05_c" :required="true"
                   :field="fields['refugio_05_c']"></FieldItem>
        <FieldItem v-model="principal.refugio_06_c" :required="true"
                   :field="fields['refugio_06_c']"></FieldItem>
        <FieldItem v-model="principal.refugio_7_c" :required="true"
                   :w-full="true"
                   type="text"
                   :field="fields['refugio_7_c']"></FieldItem>
      </div>
    </div>
  </div>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import FieldItem from "@/components/FieldItem.vue";

export default {
  name: 'ProtectionForm',
  extends: BaseForm,
  components: {
    FieldItem,
  },
  props: {
    fields: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDataValid: true,
      hasPartner: false,
      principal: {
        email1: '',
        phone_home: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        pais_c: '',
        estado_civiil_c: '',
        refugio_01_c: '',
        refugio_02_c: '',
        refugio_03_c: '',
        refugio_04_c: '',
        refugio_05_c: '',
        refugio_06_c: '',
        refugio_7_c: '',
      }
    }
  },
  mounted() {
  },
  methods: {
    validate: function () {
      let principalIsValid = this.objectValidation(this.principal);
      let partnerIsValid = true;
      if (principalIsValid && this.principal.estado_civiil_c === 'union_libre' || this.principal.estado_civiil_c === 'casado') {
        this.hasPartner = true;
        partnerIsValid = this.partnerValidation(this.partner);
      }
      this.isDataValid = principalIsValid && partnerIsValid;
      return this.isDataValid;
    },
    partnerValidation: function (object) {
      let passValidation = [];
      passValidation.push(this.validateRequired(object.email1));
      passValidation.push(this.validateRequired(object.phone_home));
      passValidation.push(this.validateRequired(object.first_name));
      passValidation.push(this.validateRequired(object.last_name));
      passValidation.push(this.validateRequired(object.birthdate));
      passValidation.push(this.validateRequired(object.pais_c));
      return !passValidation.some(item => item === false);
    },
    objectValidation: function (object) {
      let passValidation = [];
      passValidation.push(this.validateRequired(object.email1));
      passValidation.push(this.validateRequired(object.phone_home));
      passValidation.push(this.validateRequired(object.first_name));
      passValidation.push(this.validateRequired(object.last_name));
      passValidation.push(this.validateRequired(object.birthdate));
      passValidation.push(this.validateRequired(object.pais_c));
      passValidation.push(this.validateRequired(object.estado_civiil_c));
      passValidation.push(this.validateRequired(object.refugio_01_c));
      passValidation.push(this.validateRequired(object.refugio_02_c));
      passValidation.push(this.validateRequired(object.refugio_03_c));
      passValidation.push(this.validateRequired(object.refugio_04_c));
      passValidation.push(this.validateRequired(object.refugio_05_c));
      passValidation.push(this.validateRequired(object.refugio_06_c));
      passValidation.push(this.validateRequired(object.refugio_7_c));
      return !passValidation.some(item => item === false);
    },
    validateRequired: function (item) {
      return item !== undefined && item !== null && item !== '';
    },
  }
}
</script>

<style scoped>

</style>
<script>

export default {

    name: "InputBase",
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            content: this.value,
        }
    },
    methods: {}
}
</script>
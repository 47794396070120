<template>
    <div class="field">
        <label>{{ label }}<span v-if="required" class="required-item"></span></label>
        <textarea v-model="content" class="form-control form-control-text"
                  rows="10"></textarea>
    </div>
</template>

<script>
import InputBase from "@/components/fields/InputBase.vue";

export default {
    name: "InputText",
    extends: InputBase,
}
</script>

<style scoped>
.form-control-text {
    height: 6rem !important;
    width: 95% !important;
}
</style>
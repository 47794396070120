<template>
  <div v-if="type">
    <div class="container">
      <div class="principal">
        <FieldItem v-model="principal.email1" :required="true" :field="fields['email1']"></FieldItem>
        <FieldItem v-model="principal.phone_home" :required="true" :field="fields['phone_home']"
                   :label="'Teléfono'"></FieldItem>
        <FieldItem v-model="principal.first_name" :required="true" :field="fields['first_name']"></FieldItem>
        <FieldItem v-model="principal.last_name" :required="true" :field="fields['last_name']"></FieldItem>
        <FieldItem v-model="principal.birthdate" :required="true" :field="fields['birthdate']"></FieldItem>
        <FieldItem v-model="principal.pais_c" :required="true" :field="fields['pais_c']"></FieldItem>
        <FieldItem v-model="principal.estado_civiil_c" :required="true"
                   :field="fields['estado_civiil_c']"></FieldItem>
        <FieldItem v-model="principal.nivel_educativo_c" :required="true"
                   :field="fields['nivel_educativo_c']"></FieldItem>
        <FieldItem v-model="principal.usted_persona_apadrina_c" :required="true"
                   :field="fields['usted_persona_apadrina_c']"></FieldItem>
        <FieldItem v-model="principal.quien_apadrin_es_c" :required="true"
                   :field="fields['quien_apadrin_es_c']"></FieldItem>
        <FieldItem v-model="principal.mayor_18_c" :required="true"
                   :field="fields['mayor_18_c']"></FieldItem>
        <FieldItem v-model="principal.apadrina_bancarrota_c" :required="true"
                   :field="fields['apadrina_bancarrota_c']"></FieldItem>
        <FieldItem v-model="principal.provincia_apadrina_c" :required="true"
                   :field="fields['provincia_apadrina_c']"></FieldItem>
        <FieldItem v-model="principal.proceso_judicial_c" :required="true"
                   :field="fields['proceso_judicial_c']"></FieldItem>
        <FieldItem v-model="principal.enfermedad_importante_c" :required="true"
                   :field="fields['enfermedad_importante_c']"></FieldItem>
        <FieldItem v-model="principal.personas_apadrinadas_c" :required="true"
                   :field="fields['personas_apadrinadas_c']"></FieldItem>
        <FieldItem v-model="principal.observaciones_c" :required="true"
                   :w-full="true"
                   :field="fields['observaciones_c']"></FieldItem>
      </div>
      <div class="partner" v-if="principal.estado_civiil_c==='casado'||principal.estado_civiil_c==='union_libre'">
        <h3>Formulario de acompañante</h3>
        <FieldItem v-model="partner.email1" :required="true" :field="fields['email1']"></FieldItem>
        <FieldItem v-model="partner.phone_home" :required="true" :field="fields['phone_home']"
                   :label="'Teléfono'"></FieldItem>
        <FieldItem v-model="partner.first_name" :required="true" :field="fields['first_name']"></FieldItem>
        <FieldItem v-model="partner.last_name" :required="true" :field="fields['last_name']"></FieldItem>
        <FieldItem v-model="partner.birthdate" :required="true" :field="fields['birthdate']"></FieldItem>
        <FieldItem v-model="partner.pais_c" :required="true" :field="fields['pais_c']"></FieldItem>
      </div>
    </div>
  </div>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import FieldItem from "@/components/FieldItem.vue";

export default {
  name: "SponsorForm",
  extends: BaseForm,
  components: {
    FieldItem,
  },
  props: {
    fields: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDataValid: true,
      hasPartner: false,
      principal: {
        email1: '',
        phone_home: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        pais_c: '',
        estado_civiil_c: '',
        nivel_educativo_c: '',
        usted_persona_apadrina_c: '',
        quien_apadrin_es_c: '',
        mayor_18_c: '',
        apadrina_bancarrota_c: '',
        provincia_apadrina_c: '',
        proceso_judicial_c: '',
        enfermedad_importante_c: '',
        personas_apadrinadas_c: '',
        observaciones_c: '',
      },
      partner: {
        email1: '',
        phone_home: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        pais_c: '',
      }
    }
  },
  methods: {
    validate: function () {
      let principalIsValid = this.objectValidation(this.principal);
      let partnerIsValid = true;
      if (principalIsValid && this.principal.estado_civiil_c === 'union_libre' || this.principal.estado_civiil_c === 'casado') {
        this.hasPartner = true;
        partnerIsValid = this.partnerValidation(this.partner);
      }
      this.isDataValid = principalIsValid && partnerIsValid;
      return this.isDataValid;
    },
    partnerValidation: function (object) {
      let passValidation = [];
      passValidation.push(this.validateRequired(object.email1));
      passValidation.push(this.validateRequired(object.phone_home));
      passValidation.push(this.validateRequired(object.first_name));
      passValidation.push(this.validateRequired(object.last_name));
      passValidation.push(this.validateRequired(object.birthdate));
      passValidation.push(this.validateRequired(object.pais_c));
      return !passValidation.some(item => item === false);
    },
    objectValidation: function (object) {
      let passValidation = [];
      passValidation.push(this.validateRequired(object.email1));
      passValidation.push(this.validateRequired(object.phone_home));
      passValidation.push(this.validateRequired(object.first_name));
      passValidation.push(this.validateRequired(object.last_name));
      passValidation.push(this.validateRequired(object.birthdate));
      passValidation.push(this.validateRequired(object.pais_c));
      passValidation.push(this.validateRequired(object.estado_civiil_c));
      passValidation.push(this.validateRequired(object.nivel_educativo_c));
      passValidation.push(this.validateRequired(object.usted_persona_apadrina_c));
      passValidation.push(this.validateRequired(object.quien_apadrin_es_c));
      passValidation.push(this.validateRequired(object.mayor_18_c));
      passValidation.push(this.validateRequired(object.apadrina_bancarrota_c));
      passValidation.push(this.validateRequired(object.provincia_apadrina_c));
      passValidation.push(this.validateRequired(object.proceso_judicial_c));
      passValidation.push(this.validateRequired(object.enfermedad_importante_c));
      passValidation.push(this.validateRequired(object.personas_apadrinadas_c));
      passValidation.push(this.validateRequired(object.observaciones_c));
      return !passValidation.some(item => item === false);
    },
    validateRequired: function (item) {
      return item !== undefined && item !== null && item !== '';
    },
  }
}
</script>
<style scoped>

</style>